.App-Background {
  border-radius: 1vw !important;
  /* background-color: rgba(255, 255, 255, 0.5) !important; */
  height: 95vh !important;
  width: 100% !important;
  margin: 0 1vw 0 1vw !important;
}

.App-Header {
  border-radius: 0.5vw !important;
  background-color: white !important;
  height: 15vh !important;
  width: 98% !important;
  margin: 1vh auto !important;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
}

.App-Body {
  height: 80vh !important;
  width: 98% !important;
  margin: auto !important;
  display: flex !important;
}

.Desktop {
  width: 70vw !important;
  display: flex !important;
  background-color: white !important;
  border-radius: 30px !important;
  margin: 0 15px 0 0 !important;
  height: 100% !important;
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
  position: relative !important;
  justify-content: center !important;
  align-items: center !important;
}

.CanvasIMG {
  position: absolute !important;
  cursor: crosshair !important;
  border-radius: 0.2vw !important;
  max-height: 100% !important;
  max-width: 55vw !important;
  margin: auto !important;
  z-index: 0 !important;
}

.Canvas {
  position: absolute !important;
  cursor: crosshair !important;
  border-radius: 0.2vw !important;
  max-height: 100% !important;
  max-width: 55vw !important;
  z-index: 1 !important;
}

.ZoomBox {
  border: 1px #00000080 solid !important;
  border-radius: 50px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: none;
}

.SelectedColor {
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.35);
  border-radius: 20px !important;
  height: 2vw !important;
  width: 100% !important;
}

.Tools {
  height: 100% !important;
  width: 5vw !important;
  max-width: 80px !important;
  margin-left: auto !important;
  margin-right: 0.5vw !important;
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  align-content: baseline !important;
  padding: 10px 15px !important;
}

.Tools input {
  width: 80px !important;
}

.ToolButton {
  height: 6vh !important;
  cursor: pointer !important;
  padding: 5px 0 5px 0 !important;
  width: 100% !important;
  margin: 0.5vw 0 0 0 !important;
  background-color: #718c93 !important;
  border-radius: 15px !important;
  color: #FFF !important;
  font-size: 0.6vw !important;
  font-weight: bolder !important;
  text-align: center !important;
}

@media screen and (min-width: 1920px) {
  .ToolButton {
    font-size: 12px !important;
  }
}

.ToolButton label {
  pointer-events: none !important;
}

.ToolButton svg {
  height: 3vh !important;
  width: 100% !important;
  pointer-events: none !important;
}

.ConfirmarVetor {
  position: fixed !important;
  display: none;
  padding: 0 0 0 15px !important;
  border-radius: 10px !important;
  background-color: #ffffff00 !important;
}

svg path {
  pointer-events: none !important;
  fill: white !important;
}

.ToolButton:hover {
  background-color: rgb(24, 53, 129) !important;
}

.ToolButton .active path {
  fill: white !important;
}

.Tools .active {
  background-color: rgb(24, 53, 129) !important;
}

.SearchColors {
  background-color: white !important;
  height: 100% !important;
  border-radius: 0.5vw !important;
  width: 30vw !important;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
}

.SearchBox {
  width: 100% !important;
  display: inline-flex;
  align-content: center !important;
  position: relative !important;
  margin-bottom: 15px !important;
}

.SearchBox input[type="text"] {
  font-size: large !important;
  width: 70% !important;
  margin: auto !important;
  padding: 12px 10px !important;
  box-sizing: border-box !important;
  outline: none !important;
  border: 3px solid #577d8e !important;
  border-radius: 15px !important;
}

.SelectBox .css-1s2u09g-control {
  padding: 6px 0px !important;
  box-sizing: border-box !important;
  outline: none !important;
  border: 3px solid #577d8e !important;
  border-radius: 15px !important;
}

.SelectBox .css-1pahdxg-control {
  padding: 6px 0px !important;
  box-sizing: border-box !important;
  outline: none !important;
  border: 3px solid #577d8e !important;
  border-radius: 15px !important;
  box-shadow: none;
}

.PickerButton {
  width: 20% !important;
  text-align: center !important;
  display: flex;
  align-content: center !important;
  justify-content: center !important;
  margin: auto !important;
  cursor: pointer !important;
  padding: 10px !important;
  border-radius: 15px !important;
  border: 3px solid #577d8e !important;
  color: #567a98 !important;
  background-color: #d8dcda !important;
  font-size: 0.9vw !important;
  font-weight: bolder !important;
}

@media screen and (min-width: 1920px) {
  .PickerButton {
    font-size: 12px !important;
  }
}

.PickerButton:hover {
  background-color: #00000017 !important;
}

.typeColorFamily {
  display: flex;
}

.ScrollColors {
  overflow: auto !important;
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-content: baseline !important;
  justify-content: center !important;
  height: 27vw !important;
}

.showSelectOption {
  height: 10vh !important;
}

@media screen and ((min-height: 720px) or (min-height: 768px)) {
  .ScrollColors {
    height: 60% !important;
  }

  .showSelectOption {
    height: 43% !important;
  }
}

@media screen and (min-height: 1080px) {
  .ScrollColors {
    height: 70% !important;
  }

  .showSelectOption {
    height: 59% !important;
  }
}

.ScrollColorsPicker {
  height: 100% !important;
}

.ColorSquare {
  border-radius: 10px !important;
  width: 8vw !important;
  height: 8vw !important;
  margin: 0.3vw !important;
  display: flex;
  align-items: flex-end !important;
  justify-content: center !important;
  cursor: pointer !important;
  max-height: 180px !important;
  max-width: 180px !important;
}

.ColorSquareFirst {
  width: 100% !important;
}

.ColorSquare:hover {
  box-sizing: border-box !important;
  border: #ffffff4d 0.2vw solid !important;
}

.ColorLabel {
  font-size: 0.5vw !important;
  font-weight: bolder !important;
  color: white !important;
  background-color: #00000099 !important;
  border-radius: 0 0 10px 10px !important;
  width: 100% !important;
  text-align: center !important;
  inline-size: 100% !important;
  overflow-wrap: break-word !important;
}

@media screen and (min-width: 1920px) {
  .ColorLabel {
    font-size: 12px !important;
  }
}

.ColorPicker {
  position: absolute !important;
  display: none;
  width: 200px !important;
  top: 20% !important;
  right: 9vw !important;
  padding: 20px !important;
  border: '1px solid #ccc'
}

.ColorFamily {
  margin: 5px !important;
  cursor: pointer !important;
  font-size: 0.9vw !important;
  font-weight: bold !important;
  width: 23% !important;
  height: 6vh !important;
}

.ColorFamily:hover,
.ColorFamily.active {
  background-color: #009cc4 !important;
  color: white !important;
}

.UsedColors {
  position: absolute !important;
  width: 100% !important;
  height: 8vh !important;
  bottom: 0 !important;
  max-width: 60vw !important;
  max-height: 150px !important;
  display: flex;
  align-items: center !important;
  align-content: center !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
  background-color: #FFF !important;
  margin: 2% !important;
  z-index: 2 !important;
}

.UsedColors div {
  height: 6vh !important;
  margin-right: 5px !important;
  cursor: pointer !important;
  color: white !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  display: flex;
  align-items: flex-end !important;
  border-radius: 1vh !important;
}

.UsedColors div span {
  background-color: #00000017 !important;
  width: 100% !important;
  padding: 5px !important;
  border-radius: 0 0 1vh 1vh !important;
}

.SearchCancelButton {
  cursor: pointer !important;
  padding: 5px !important;
  position: absolute !important;
  right: 9vw !important;
  top: 2vh !important;
  color: #577d8e !important;
  font-weight: bolder !important;
  font-size: large !important;
}

.SearchCancelButton:hover,
.BotaoVoltar:hover {
  background-color: #00000017 !important;
}

.BotaoVoltar {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 3vh !important;
  height: 3vh !important;
  padding: 5px !important;
  cursor: pointer !important;
  border-radius: 100px !important;
  margin-top: 2px !important;
  margin-left: 2px !important;
}

.SetaVoltar {
  position: relative !important;
  padding: 0.6vh !important;
  box-shadow: 2px -2px 0 0px #000 inset;
  border: solid transparent !important;
  border-width: 0 0 1px 1px !important;
  transform: rotate(45deg) !important;
}

.LogoImagem {
  margin-left: 5px !important;
  position: absolute !important;
  display: none;
  left: 0 !important;
  top: 0 !important;
}

.AvisoCanvas {
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  font-size: large !important;
  position: absolute !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  pointer-events: none !important;
}

.Tools,
.ToolButton,
::after,
::before {
  box-sizing: unset !important;
}

.ModalTutorialLabel {
  color: #1f87a7;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.ModalTutorialLabel span {
  background-color: #d1e7ed;
}

.modal {
  --bs-modal-width: 750px;
}

.mt-n1 {
  margin-top: -4rem !important;
}
