.Step1Button {
  height: 8vh;
  background-color: #b8ced7;
  color: #183581;
  border: 4px solid #183581;
  border-radius: 10px;
  margin: 5px 30px;
  padding: 0 1vw;
  cursor: pointer;
  font-size: 1vw;
  font-weight: bold;
}

.Step1Button:hover,
.Step1Button.active {
  background-color: #183581;
  color: white;
}
